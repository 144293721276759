import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import cn from 'classnames'
import useErudusStats from '../hooks/useErudusStats'

import Page from '../components/Page'
import { Container } from '../components/elements'

import SEO from '../components/SEO'
import Schema from '../components/Schema'
import ExpandableImage from '../components/ExpandableImage'
import Testimonials from '../components/Testimonials'
import NewsArticleLink from '../components/articles/Listings/NewsArticleLink'
import EditorialArticleLink from '../components/articles/Listings/EditorialArticleLink'
import Newsletter from '../components/Newsletter'

import LeadSpotSVG from '../assets/svg/home/lead-spot.svg'
import ArrowRightSVG from '../assets/svg/arrow.svg'

const IndexPage = ({ path }) => {
  const erudusStats = useErudusStats()

  const {
    site,
    newsArticles,
    whatWeDoImage,
    editorialArticles,
  } = useStaticQuery(graphql`
    query HomePageQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
      newsArticles: allDatoCmsNewsArticle(
        sort: { fields: displayDate, order: DESC }
        limit: 4
      ) {
        edges {
          node {
            title
            slug
            relPath
            category
            categoryLink
            pubDateTime
            heroImage {
              url
              alt
              title
              customData
            }
            thumbnailImage {
              url
              alt
              title
              customData
            }
          }
        }
      }
      whatWeDoImage: file(relativePath: { eq: "home/what-we-do-screen.png" }) {
        modal: childImageSharp {
          ...SharpImageModalFragment
        }
        childImageSharp {
          fixed(width: 500) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      editorialArticles: allDatoCmsEditorialArticle(
        sort: { fields: displayDate, order: DESC }
        limit: 3
      ) {
        edges {
          node {
            title
            slug
            relPath
            category
            categoryLink
            pubDateTime
            heroImage {
              url
              alt
              title
              customData
            }
          }
        }
      }
    }
  `)

  return (
    <Page className="p-home">
      <SEO
        path={path}
        title={site.siteMetadata.title}
        description={site.siteMetadata.description}
      />
      <Schema />

      <section id="lead-spot" className="c-leadSpot pb-0">
        <Container>
          <div className="c-leadSpot__block row">
            <div className="c-leadSpot__body col-12 col-md-6">
              <h1 className="c-leadSpot__header pr-3">
                Erudus is a market leading source of{' '}
                <strong>accurate allergy</strong>, <strong>nutritional</strong>{' '}
                and <strong>technical product data</strong>, populated by the
                food industry.
              </h1>
            </div>
            <div className="c-leadSpot__image col-12 col-md-6">
              <LeadSpotSVG className="img-fluid" />
            </div>
          </div>
        </Container>
      </section>

      <section id="what-we-do" className="c-section c-whatWeDo">
        <div className="c-wave c-wave--top c-wave--bg-white">
          <svg
            viewBox="0 0 500 150"
            preserveAspectRatio="none"
            style={{ height: '100%', width: '100%' }}
          >
            <path
              d="M-0.84,121.88 C183.68,-172.20 355.25,317.27 501.41,20.22 L500.00,150.00 L0.00,150.00 Z"
              style={{ stroke: 'none', fill: '#F4F4F4' }}
            ></path>
          </svg>
        </div>

        <Container>
          <div className="c-whatWeDo__block row">
            <div className="c-whatWeDo__body col-12 col-lg-6">
              <h3 className="c-whatWeDo__title">What we do</h3>
              <h1 className="c-whatWeDo__header">
                We enable the entire food industry to share product data clearly
                and easily.
              </h1>
              <p className="c-whatWeDo__text">
                All the latest data on a product, from the allergens it may
                contain to the way it’s packaged, is instantly available to
                everyone in the supply chain simply by logging in.
              </p>
            </div>
            <div className="c-whatWeDo__image col-12 col-lg-6 text-center">
              <ExpandableImage imageData={whatWeDoImage.modal}>
                <picture>
                  <source
                    srcSet={whatWeDoImage.childImageSharp.fixed.srcSetWebp}
                    type="image/webp"
                  />
                  <img
                    srcSet={whatWeDoImage.childImageSharp.fixed.srcSet}
                    src={whatWeDoImage.childImageSharp.fixed.src}
                    width={whatWeDoImage.childImageSharp.fixed.width}
                    height={whatWeDoImage.childImageSharp.fixed.height}
                    title="Erudus is enabling the entire food industry."
                    alt="Screenshot of the Erudus dashboard in action."
                    className="img-fluid"
                    loading="lazy"
                  />
                </picture>
              </ExpandableImage>
            </div>
          </div>
        </Container>

        <div className="c-wave c-wave--bottom c-wave--bg-white">
          <svg
            viewBox="0 0 500 150"
            preserveAspectRatio="none"
            style={{ height: '100%', width: '100%' }}
          >
            <path
              d="M-3.67,142.59 C202.31,100.16 277.93,-53.78 503.67,106.08 L500.00,0.00 L0.00,0.00 Z"
              style={{ stroke: 'none', fill: '#F4F4F4' }}
            ></path>
          </svg>
        </div>
      </section>

      <section id="save-time-and-money" className="c-timeMoney">
        <Container className="c-timeMoney__outer">
          <div className="c-timeMoney__inner">
            <div className="c-timeMoney__block row">
              <div className="c-timeMoney__body col-12 col-lg-6">
                <p className="f-introPara mb-4">
                  There are many stakeholders in modern supply chains, and
                  strict legislation around food products. Mistakes are not only
                  too costly to contemplate, but too risky.
                </p>
                <p className="mb-4">
                  Erudus takes the guesswork out of food production and sales,
                  saving businesses time and labour. Our range of subscription
                  packages ensure that everyone in the industry can access the
                  information they need quickly and easily, and rely on it to be
                  up-to-date.
                </p>
              </div>
              <div className="col-12 col-lg-6">
                <div className="c-timeMoneyStats">
                  <h1 className="c-timeMoneyStats__header">
                    We save time and money for…
                  </h1>
                  <ul className="c-timeMoneyStats__points">
                    <li className="c-timeMoneyStats__point">
                      <span className="f-bigNumber mr-2">
                        {erudusStats.manufacturerCount}
                      </span>
                      {` Food Manufacturers`}
                    </li>
                    <li className="c-timeMoneyStats__point">
                      <span className="f-bigNumber mr-2">
                        {erudusStats.wholesalerCount}
                      </span>
                      {` Wholesalers`}
                    </li>
                    <li className="c-timeMoneyStats__point">
                      <span className="f-bigNumber mr-2">
                        {erudusStats.catererRetailerCount}
                      </span>
                      {` Caterers & Retailers`}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <section id="product-specifications-count" className="c-prodSpecCount">
        <Container>
          <div className="row">
            <div className="c-prodSpecCount__body col-12 text-center">
              <h2 className="c-prodSpecCount__header">
                {`${erudusStats.productCount} product specifications and counting`}
              </h2>
              <p className="c-prodSpecCount__text">
                Find out what's in it for you
              </p>
            </div>
          </div>

          <div className="c-prodSpecCount__block row">
            <div className="c-prodSpecCount__item is-manufacturer col-12 col-sm-6 col-lg-3 text-center">
              <img
                src="/images/icons/For-Manufacturers.png"
                srcSet="/images/icons/For-Manufacturers.svg"
                alt="Manufacturers icon"
                className="c-prodSpecCount-icon img-fluid"
                loading="lazy"
                height="160"
                width="185"
              />
              <Link to="/manufacturers" className="c-button c-button--audience">
                <ArrowRightSVG
                  className="c-icon mr-2"
                  width="16.058"
                  height="12.121"
                />
                For <strong>Manufacturers</strong>
              </Link>
            </div>

            <div className="c-prodSpecCount__item is-wholesaler col-12 col-sm-6 col-lg-3 text-center">
              <img
                src="/images/icons/For-Wholesalers.png"
                srcSet="/images/icons/For-Wholesalers.svg"
                alt="Wholesalers icon"
                className="c-prodSpecCount-icon img-fluid"
                loading="lazy"
                height="160"
                width="185"
              />
              <Link to="/wholesalers" className="c-button c-button--audience">
                <ArrowRightSVG
                  className="c-icon mr-2"
                  width="16.058"
                  height="12.121"
                />
                For <strong>Wholesalers</strong>
              </Link>
            </div>

            <div className="c-prodSpecCount__item is-caterer col-12 col-sm-6 col-lg-3 text-center">
              <img
                src="/images/icons/For-Caterers.png"
                srcSet="/images/icons/For-Caterers.svg"
                alt="Caterers icon"
                className="c-prodSpecCount-icon img-fluid"
                loading="lazy"
                height="160"
                width="185"
              />
              <Link to="/caterers" className="c-button c-button--audience">
                <ArrowRightSVG
                  className="c-icon mr-2"
                  width="16.058"
                  height="12.121"
                />
                For <strong>Caterers</strong>
              </Link>
            </div>

            <div className="c-prodSpecCount__item is-retailer col-12 col-sm-6 col-lg-3 text-center">
              <img
                src="/images/icons/For-Retailers.png"
                srcSet="/images/icons/For-Retailers.svg"
                alt="Retailers icon"
                className="c-prodSpecCount-icon img-fluid"
                loading="lazy"
                height="160"
                width="185"
              />
              <Link to="/retailers" className="c-button c-button--audience">
                <ArrowRightSVG
                  className="c-icon mr-2"
                  width="16.058"
                  height="12.121"
                />
                For <strong>Retailers</strong>
              </Link>
            </div>
          </div>
        </Container>
      </section>

      <Testimonials />

      <section id="related-news" className="c-otherNews c-otherNews--bg">
        <Container>
          <div className="c-otherNews__inner">
            <div className="row">
              <div className="c-otherNews__header col-12">
                <h2>Latest News</h2>

                <Link to="/news" className="c-postReturnLink">
                  <ArrowRightSVG
                    className="c-icon mr-2"
                    width="16.058"
                    height="12.121"
                  />
                  <span className="c-postReturnLink__text">All News</span>
                </Link>
              </div>
            </div>

            <div className="c-otherNews__items row">
              {newsArticles.edges.map(({ node }, i) => (
                <div
                  key={node.slug}
                  className={cn('col-12 col-sm-6 col-lg-3', {
                    'd-none d-lg-block': [2, 3].includes(i),
                  })}
                >
                  <NewsArticleLink article={node} />
                </div>
              ))}
            </div>
          </div>
        </Container>
      </section>

      <section id="latest-editorial" className="c-latestEditorial">
        <Container>
          <div className="c-latestEditorial__inner">
            <div className="row">
              <div className="c-latestEditorial__header col-12">
                <h2>Latest Editorial</h2>

                <Link to="/editorial" className="c-postReturnLink">
                  <ArrowRightSVG
                    className="c-icon mr-2"
                    width="16.058"
                    height="12.121"
                  />
                  <span className="c-postReturnLink__text">All Editorial</span>
                </Link>
              </div>
            </div>

            <div className="row">
              {editorialArticles.edges.map(({ node }, i) => (
                <div
                  key={node.slug}
                  className={cn('col-12 col-sm-6 col-lg-4', {
                    'd-none d-lg-block': i === 2,
                  })}
                >
                  <EditorialArticleLink article={node} />
                </div>
              ))}
            </div>
          </div>
        </Container>
      </section>

      <Newsletter />
    </Page>
  )
}

export default IndexPage
